import React from "react"
import Layout from "../components/layout"
import "./index.css"
import SEO from "../components/seo"
import CallIcon from "../../static/call_FILL0_wght400_GRAD0_opsz48.svg"

export default () => {
  return (
    <Layout>
      <SEO
        title={"Cashback·Medical"}
        description={"Medical debt collection in Florida"}
      />
      <div className="mc-page container-fluid">
        <div className="row">
          <div style={{marginBottom:"75px"}} className="col-md-7">
            <div>
              <div className="accent-div"/>
                <h1>A better way for medical debt collection</h1>
              <div className="spacing">
                <p><b>Cashback·Medical</b> is a revolutionary tool for providers to recover past-due patient accounts.
                  By incentivizing patients with cashback offers and payment plans,
                  we collect more accounts than traditional debt collection practices.</p>
                <p>Lets talk about your practice's patient accounts.</p>
              </div>
            </div>
            <div className={"spacing d-flex align-items-center"}>
              <img src={CallIcon} alt="Call us" />
              <div>
                <h1><a href="tel:407-259-2969">(407) 259-2969</a></h1>
              </div>
            </div>
          </div>
          <div className="col-md-5 ">
            <img className="img-offers" alt={"offers"} src={"phone1_new.png"}/>
          </div>
        </div>
      </div>
    </Layout>
  )
}